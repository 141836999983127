const isEmpty = require('lodash/isEmpty')

export default {
  methods: {
    changeSelectUrl(query) {
      this.$router.push({ path: this.$route.path, query })
      this.getData(query)
    },

    initbasicData (query) {
      let result = !isEmpty(query) ? query : (this.$route.query || {})
      Object.assign(this.basicdata, {
        searchkey: result.searchkey ? result.searchkey : '',
        page: parseInt(result.page) || 1,
        pagesize: result.limit || this.basicdata.pagesize
      })
    }
  }
}
